@import '@/styles/colors.module';

.wrapper {
  :global {
    .ant-table-cell:first-of-type {
      padding-left: 64px;
    }
  }

  .ellipsisParagraph {
    color: inherit !important;
  }

  .priceCol {
    text-align: right !important;
    font-weight: 500;
  }

  .expired {
    color: $gold;
  }
}
