@import '@/styles/colors.module';

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .input,
  .select,
  .datePicker {
    width: 140px;
  }

  .inputLong {
    width: 420px;
  }

  .text,
  .textLong {
    width: 140px;
    flex-shrink: 0;
    font-weight: 400;
    color: $light_gray;
    font-size: 14px;
  }

  .textLong {
    width: 270px;
  }
}
