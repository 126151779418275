@import '@/styles/colors.module';

.headerWrapper {
  padding: 44px 64px;
  display: flex;
  align-items: center;
  gap: 64px;
  background-color: $white;

  :global {
    .ant-tabs-nav {
      &::before {
        border-bottom: none !important;
      }

      &-operations {
        display: none !important;
      }
    }
  }

  .tabs {
    font-weight: 500;
    line-height: 20px;
  }

  .title {
    min-width: max-content;
    font-size: 32px;
    line-height: 28px;
    font-weight: 600;
  }
}

.contentWrapper {
  padding: 28px 32px;
  background: $gray;
}
