@import '@/styles/colors.module';

.wrapper {
  padding: 32px 64px;
  background-color: white;

  .header {
    padding: 16px 0 48px;
    overflow: hidden;
  }

  .inputIcon {
    cursor: pointer;
    color: $light_gray;
    margin-right: 2px;
  }

  .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }

  .input {
    width: 540px;
  }

  .dateText {
    font-size: 14px;
    font-weight: 500;
  }

  .treeSelect {
    width: 320px;
  }

  .treePlaceholder {
    color: $black;
  }
}
