.purchaseLink {
  display: flex;
  gap: 4px;
  font-weight: 500;

  svg {
    transition: opacity 0.1s ease-in;
    opacity: 0;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}