@import '@/styles/colors.module';

.wrapper {
  .priceRow {
    text-align: right !important;
  }

  .table {
    :global {
      .ant-table-row-expand-icon-cell {
        padding: 16px !important;
      }

      .ant-table-row-expand-icon {
        display: none !important;
      }

      .ant-table-expanded-row {
        .ant-table-cell {
          background-color: white !important;
        }
      }
    }

    .remains {
      font-weight: 500;
    }

    .expandedRow {
      background: $gray;
      padding-right: 0;

      .linkedButton {
        opacity: 0.7;

        .viconActive {
          transform: rotate(-180deg);
        }
      }

      :global {
        .ant-table-cell {
          background: $gray !important;
        }
      }
    }
  }
}
