@import '@/styles/colors.module';

.wrapper {
  background-color: white;

  .header {
    padding: 34px 64px;
    overflow: hidden;
  }

  .title {
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
  }

  .tab {
    margin-top: 14px;
    :global {
      .ant-tabs-tab {
        font-weight: 500;
        color: $light_blue;
        font-size: 16px;
        padding: 0 0 12px 0;
        line-height: 20px;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: black;
      }

      .ant-tabs-nav {
        margin: 0;

        &::before {
          border-bottom: none;
        }

        &-operations {
          display: none !important;
        }
      }
    }
  }
}
