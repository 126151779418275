@import '@/styles/colors.module';

.initialContent {
  :global {
    .ant-list-split .ant-list-item {
      border-block-end: 0;
    }
  }

  background-color: $white;
  padding: 32px 64px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.002em;
  text-align: left;

  .subtitle {
    font-size: 20px;

    button {
      font-size: 20px;
      border-bottom: 1px dashed $blue;
    }
  }
}

.npaList {
  padding: 0 12px !important;

  .listItem {
    font-size: 16px;
  }
}

.listItem {
  border-block-end: 0;

  .link {
    color: $light_blue;

    &:hover {
      color: $blue;
    }
  }
}

.popover {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: $dark_gray;
    border-radius: 16px;
  }

  :global {
    .ant-popover-content {
      width: 372px;
    }

    .ant-list-split .ant-list-item {
      border-block-end: 0;
    }

    .ant-popover-inner {
      padding: 12px 0 !important;
      border-radius: 16px !important;
    }

    .ant-popover-title {
      border-bottom: 1px solid $border_gray !important;
    }

    .ant-popover-inner-content {
      height: 560px !important;
      overflow-y: scroll;
    }
  }

  .popoverTitleWrapper {
    padding: 0 12px;

    .popoverTitle {
      height: 60px;
      font-size: 22px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.002em;
    }

    .cross {
      cursor: pointer;
      color: $dark_gray;
    }
  }
}
