@import '@/styles/colors.module';

.wrapper {
  padding: 34px 64px;
  background-color: white;

  .title {
    min-width: 220px;
    font-size: 32px;
    line-height: 28px;
    font-weight: 600;
    white-space: nowrap;
  }
}