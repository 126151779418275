@import '@/styles/colors.module';
@import "@/styles/mixins";

.title {
  @include truncate;
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $light_gray;
}

.chipsList {
  column-gap: 4px;
  row-gap: 8px;
}
