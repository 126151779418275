.purchaseSum {
  text-align: right;
}

.linkedPurchase {
  width: 1186px;
  margin-left: 48px;
  display: grid;
  grid-template: auto / repeat(2, fit-content(300px)) 1fr;
  gap: 50px;
}