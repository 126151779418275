@import '@/styles/colors.module';

.content {
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .text {
      color: $light_gray;
      font-weight: 500;
      display: flex;
      gap: 10px;

      .name {
        width: 220px;
      }

      .type {
        width: 180px;
      }

      .value {
        width: 520px;
      }
    }

    .addCharItemButton {
      width: fit-content;
    }
  }
}
