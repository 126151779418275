@import '@/styles/colors.module';

.printTableHeader,
.printTableRow {
  padding: 8px;
  text-align: left;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 1px;
    background: $gray;
  }
}

.printTitle {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
}

.printTableCell {
  vertical-align: text-top;
  padding: 8px;
}

.stage {
  width: 450px;
}

.date {
  width: 254px;
}
