@import '@/styles/colors.module';

.wrapper {
  background-color: $gray;
  min-height: calc(100vh - 96px);

  .filters {
    padding: 10px 64px 34px;
    background-color: white;
  }

  .content {
    padding: 32px 64px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 32px;
  }
}
