@import '@/styles/colors.module';

.wrapper {
  padding: 32px 64px;
  height: 100%;

  .table {
    :global {
      .ant-table-thead .ant-table-cell {
        white-space: nowrap;
      }
    }
  }

  .region {
    min-width: 200px;
  }

  .documentation {
    min-width: 266px;
  }

  .suppliers {
    min-width: 272px;
  }

  .row {
    vertical-align: top;
  }
}
